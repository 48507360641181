import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../components/layout/ContentWrapper';
import TextHeadingWrapper from '../../components/layout/TextHeadingWrapper';

import { ViewportBreakpoints } from '../../components/cssConstants';

import MissionRocketIcon from '../../images/svg/icons/about-mission-rocket.svg';
import ValueRunIcon from '../../images/svg/icons/about-value-run.svg';
import ValueCelebrateIcon from '../../images/svg/icons/about-value-celebrate.svg';
import ValueExceptionalIcon from '../../images/svg/icons/about-value-exceptional.svg';
import ValueSimpleIcon from '../../images/svg/icons/about-value-simple.svg';
import ValueTogetherIcon from '../../images/svg/icons/about-value-together.svg';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';

const GreyWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.grey10};
`;

const BlueWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand10};
`;

const VerticallySpacedText = styled.div`
  margin-bottom: 40px;
`;

const Hero = styled.div`
  padding: 80px 0 80px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 120px 0 120px 0;
  }
`;

const HeroBody = styled.div`
  text-align: center;
  margin: 0 auto;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 70%;
  }
`;

const MissionStatement = styled.div`
  border-radius: 3px;
  box-shadow: 0 0 4px 1px rgba(71, 91, 104, 0.1);
  border-top: solid 4px ${({ theme: { colors } }) => colors.brand20};

  margin: -180px auto 300px auto;
  transform: translateY(50%);

  display: flex;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    width: 70%;
    margin: -120px auto 240px auto;
  }
`;

const MissionIcon = styled.div`
  flex: 1 1 auto;
  background-color: ${({ theme: { colors } }) => colors.brand10};

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 1 27%;
  }
`;

const MissionBody = styled.div`
  flex: 1 1 auto;
  background-color: ${({ theme: { colors } }) => colors.white};

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 1 73%;
  }
`;

const MissionBodyWrapper = styled.div`
  padding: 40px;
`;

const MissionIconWrapper = styled.div`
  padding: 40px 0;
  width: auto;

  svg {
    height: 80px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    svg {
      height: 120px;
    }
  }
`;

const TimelineWrapper = styled.div`
  position: relative;
  margin: 60px 0;
`;

const Timeline = styled.div`
  margin: 0 auto;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 75%;
  }

  @media (min-width: ${ViewportBreakpoints.tabletLandscapeUpper}) {
    width: 50%;
  }

  &:after {
    content:"";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 2px dashed ${({ theme: { colors } }) => colors.brand};
  }
`;

const MarkerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const TextMarker = styled.div`
  background-color: ${({ theme: { colors } }) => colors.warning};
  padding: 10px 38px;
  border-radius: 5px;
`;

const YearMarker = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand180};
  padding: 5px 45px;
  border-radius: 5px;
`;

const EventWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: ${props => props.right ? 'flex-end' : 'flex-start'};
  margin-bottom: 30px;

  &:after {
    content:"";
    position: absolute;
    top: -9px;
    bottom: 0;
    left: calc(50% - 9px);
    width: 20px;
    height: 20px;
    border: 1px solid ${({ theme: { colors } }) => colors.brand};
    border-radius: 10px;
    background-color: ${({ theme: { colors } }) => colors.white};
    box-sizing: border-box;
    z-index: 1;
  }
`;

const EventCard = styled.div`
  border-radius: 3px;
  box-shadow: 0 0 4px 1px rgba(71, 91, 104, 0.1);
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 30px 40px;
  box-sizing: border-box;
  z-index: 0;
  width: 100%;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    z-index: -2;
    width: 50%;
    padding: 30px 40px 30px 60px;
  }

  > p:not(:last-child) {
    padding-bottom: 10px;
  }
`;

const Date = styled(Text).attrs({
  weight: 600,
  uistyle: 'info',
})``;

const Event = styled(Text).attrs({
  size: 'xl',
  weight: 600,
  uistyle: 'brand160',
})``;

const Description = styled(Text).attrs({
  size: 'lg',
})`
`;

const BrandValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const BrandValue = styled.div`
  flex: 1 1 auto;
  margin: 20px;
  padding: 40px 30px;

  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0 0 4px 1px rgba(71, 91, 104, 0.1);
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  background-color: ${({ theme: { colors } }) => colors.white};

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 calc(50% - 40px);
  }

  @media (min-width: ${ViewportBreakpoints.tabletLandscapeUpper}) {
    flex: 0 1 calc(33% - 40px);
  }

  > * {
    padding-bottom: 20px;
    text-align: center;
  }
`;

const BrandValueIcon = styled.div`
`;

const BrandValueHeading = styled(H3).attrs({
  uistyle: 'brand160',
})``;

const BrandValueDescription = styled(Text).attrs({
  size: 'xl',
})``;

const CenteredWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const header = {
  backgroundColor: 'grey10',
  invertColors: false,
};

class AboutPage extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="About Us - Rotaready"
          description="Learn our story, our brand values and discover why our mission is to bring businesses and their people closer together."
          url="about"
        />

        <GreyWrapper>
          <ResponsiveContainer>
            <Hero>
              <HeroBody>
                <TextHeadingWrapper>
                  <H1 uistyle="brand160" text="About us" />
                </TextHeadingWrapper>

                <VerticallySpacedText>
                  <Text size="xl">
                    We used to work in pubs, bars, hotels, shops and theme parks. And managing rotas, holiday accrual and timesheets was <b>our least favourite thing</b>. It took hours &mdash; people were still dissatisfied with their shifts and we were forever wrestling with our wage bill.
                  </Text>
                </VerticallySpacedText>

                <VerticallySpacedText>
                  <Text size="xl">
                    Some places had "market leading" rota software, but it was <b>slow and clunky</b> &mdash; so rotas were still done on Excel and holidays on paper.
                  </Text>
                </VerticallySpacedText>

                <VerticallySpacedText>
                  <Text size="xl">
                    We believed there should be a better way, so in 2015 <b>we built Rotaready</b>. Version 1.0 could build rotas automatically, had free mobile apps, integrated out-of-the-box with other systems and crucially, it was fun to use.
                  </Text>
                </VerticallySpacedText>

                <Text size="xl">
                  We've continually innovated since then, but <b>we haven't forgotten our roots</b> &mdash; our customers shape our vision and our roadmap. Whether you're in head office or on the shop floor, we're here for everyone.
                </Text>
              </HeroBody>
            </Hero>

            <MissionStatement>
              <MissionIcon>
                <MissionIconWrapper>
                  <MissionRocketIcon />
                </MissionIconWrapper>
              </MissionIcon>
              <MissionBody>
                <MissionBodyWrapper>
                  <H2 uistyle="brand160" text="Our mission is to bring businesses and their people closer together." />
                </MissionBodyWrapper>
              </MissionBody>
            </MissionStatement>
          </ResponsiveContainer>
        </GreyWrapper>

        <ResponsiveContainer>
          <TimelineWrapper>
            <Timeline>
              <MarkerWrapper>
                <TextMarker>
                  <H2 uistyle="white" text="Our story so far" />
                </TextMarker>
              </MarkerWrapper>

              <MarkerWrapper>
                <YearMarker>
                  <H4 uistyle="white" text="2015" />
                </YearMarker>
              </MarkerWrapper>

              <EventWrapper>
                <EventCard>
                  <Date text="February 2015" />
                  <Event text="Rotaready was born" />
                  <Description text="Carl and Jamie turn their ideas into a reality." />
                </EventCard>
              </EventWrapper>

              <EventWrapper right>
                <EventCard>
                  <Date text="November 2015" />
                  <Event text="Our first customer" />
                  <Description text="After a few months building our product, we welcomed our first customer on board." />
                </EventCard>
              </EventWrapper>

              <MarkerWrapper>
                <YearMarker>
                  <H4 uistyle="white" text="2016" />
                </YearMarker>
              </MarkerWrapper>

              <EventWrapper>
                <EventCard>
                  <Date text="April 2016" />
                  <Event text="We moved out..." />
                  <Description text="...from the spare room in Carl's parents house to our first office in Croydon, South London." />
                </EventCard>
              </EventWrapper>

              <EventWrapper right>
                <EventCard>
                  <Date text="September 2016" />
                  <Event text="Our first hire" />
                  <Description text="Team Rotaready grew from two to three, as our first full-time engineer joined us." />
                </EventCard>
              </EventWrapper>

              <MarkerWrapper>
                <YearMarker>
                  <H4 uistyle="white" text="2017" />
                </YearMarker>
              </MarkerWrapper>

              <EventWrapper>
                <EventCard>
                  <Date text="October 2017" />
                  <Event text="Investment from True Capital" />
                  <Description text="We secured the backing of one of Europe's leading retail-focused Venture Capital firms." />
                </EventCard>
              </EventWrapper>

              <MarkerWrapper>
                <YearMarker>
                  <H4 uistyle="white" text="2018" />
                </YearMarker>
              </MarkerWrapper>

              <EventWrapper right>
                <EventCard>
                  <Date text="June 2018" />
                  <Event text="We hit 10,000 users" />
                  <Description text="10,000 people were using Rotaready, trusting us with their rotas, holidays and more." />
                </EventCard>
              </EventWrapper>

              <EventWrapper>
                <EventCard>
                  <Date text="November 2018" />
                  <Event text="Business Enabler of The Year" />
                  <Description text="We won at the prestigious National Business Awards sponsored by Lloyds Bank!" />
                </EventCard>
              </EventWrapper>

              <MarkerWrapper>
                <YearMarker>
                  <H4 uistyle="white" text="2021" />
                </YearMarker>
              </MarkerWrapper>

              <EventWrapper right>
                <EventCard>
                  <Date text="July 2021" />
                  <Event text="Moved to Victoria" />
                  <Description text="We graduated from our Croydon office to a brand new space in Victoria Station, London." />
                </EventCard>
              </EventWrapper>

              <MarkerWrapper>
                <YearMarker>
                  <H4 uistyle="white" text="2022" />
                </YearMarker>
              </MarkerWrapper>

              <EventWrapper>
                <EventCard>
                  <Date text="August 2022" />
                  <Event text="The Access Group" />
                  <Description text="We were acquired by The Access Group and joined their hospitality division, alongside well loved products like DesignMyNight, Trail, Acteol and ProcureWizard!" />
                </EventCard>
              </EventWrapper>

              <MarkerWrapper>
                <YearMarker>
                  <H4 uistyle="white" text="Today" />
                </YearMarker>
              </MarkerWrapper>

              <EventWrapper right>
                <EventCard>
                  <Description text="We've doubled down on our commitment to continuous innovation, delivering over 300 product updates and improvements in the last year alone." />
                </EventCard>
              </EventWrapper>
            </Timeline>
          </TimelineWrapper>
        </ResponsiveContainer>

        <BlueWrapper>
          <ResponsiveContainer>
            <ContentWrapper>
              <TextHeadingWrapper>
                <H2 text="Our values" uistyle="brand160" />
              </TextHeadingWrapper>

              <BrandValueWrapper>
                <BrandValue>
                  <BrandValueIcon>
                    <ValueRunIcon />
                  </BrandValueIcon>
                  <BrandValueHeading text="Run with it" />
                  <BrandValueDescription text="We don't shy away from the biggest challenges, we seize them. We have the courage to take the road less travelled. We move at speed and fail fast, letting data decide the outcome." />
                </BrandValue>

                <BrandValue>
                  <BrandValueIcon>
                    <ValueCelebrateIcon />
                  </BrandValueIcon>
                  <BrandValueHeading text="Celebrate our customer" />
                  <BrandValueDescription text="Our customers' successes are our successes. We win when they win. Our curiosity drives us to understand their problems better and our empathy allows us to deliver solutions that exceed their expectations." />
                </BrandValue>

                <BrandValue>
                  <BrandValueIcon>
                    <ValueExceptionalIcon />
                  </BrandValueIcon>
                  <BrandValueHeading text="Be exceptional" />
                  <BrandValueDescription text="Your incredible talent, passion and imagination are why you're here and they transcend everything you do. We're relentless in the pursuit of innovation and brilliance. Nothing stands in our way." />
                </BrandValue>

                <BrandValue>
                  <BrandValueIcon>
                    <ValueSimpleIcon />
                  </BrandValueIcon>
                  <BrandValueHeading text="Simple is beautiful" />
                  <BrandValueDescription text="We relish the challenge of making things simple. The absence of complication creates space for intuition and clarity. We'll keep iterating until we get there." />
                </BrandValue>

                <BrandValue>
                  <BrandValueIcon>
                    <ValueTogetherIcon />
                  </BrandValueIcon>
                  <BrandValueHeading text="Win together" />
                  <BrandValueDescription text="We learn obsessively. We take risks and break down barriers. We accelerate each other to achieve greater things. Together we win." />
                </BrandValue>
              </BrandValueWrapper>
            </ContentWrapper>
          </ResponsiveContainer>
        </BlueWrapper>

        <ResponsiveContainer>
          <ContentWrapper>
            <TextHeadingWrapper>
              <H2 text="Want to get to know us better?" uistyle="brand160" />
            </TextHeadingWrapper>

            <CenteredWrapper>
              <Link to="/team">
                <Button uistyle="primary" text="Meet the team" size="lg" />
              </Link>
            </CenteredWrapper>
          </ContentWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default AboutPage;

export const query = graphql`
  query {
    teamPhoto: file(relativePath: { eq: "misc/about-team.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
  }
`;
